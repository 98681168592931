<template>
  <PageWithHeader class="register px-16">
    <template #title><t path="events_2">Upcoming events</t></template>
    <div class="fill df-col df-between">
      <div class="tab-wrap d-f">
        <div id="tab-l" :class="['tab-item mr-16', {active:actIndex===0}]" @click="swichTab(0)">
          <t path="events_3" class="text-nowrap">Events Today</t>
        </div>
        <div id="tab-r" :class="['tab-item', {active:actIndex===1}]" @click="swichTab(1)">
          <t path="events_4" class="text-nowrap">Economic Calendar</t>
        </div>
        <div id="s-l" ref="sLine" :class="['s-line left',{'right': actIndex===1}]"></div>
      </div>
      <div class="flex-1">
        <div class="list-wrap py-16" v-if="actIndex===0">
          <Holder :content="eventList">
            <AsyncNullableList
              :identify="v => v.id"
              :list-data="eventList"
            >
              <template #="{ item }">
                <div class="list-item mb-12">
                  <TheEventItem :item="item" @click="itemClick(item)"/>
                </div>
              </template>
            </AsyncNullableList>
          </Holder>
        </div>
        <TheEconomicCalendar v-if="actIndex===1" />
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, onMounted, ref, shallowRef } from 'vue'
import TheEventItem from '@/modules/home/components/TheEventItem.vue'
import TheEconomicCalendar from '@/modules/home/components/TheEconomicCalendar.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import { readEventsList, EventItem } from '@/modules/home/home.api'
import { formatDateTime } from '@/common/format'
import { eventEnter } from '@/modules/home/home'

export default defineComponent({
  name: 'Register',
  components: { PageWithHeader, TheEventItem, TheEconomicCalendar, AsyncNullableList, Holder },

  setup () {
    const actIndex = shallowRef(0)
    const sLine = ref<HTMLElement>()
    const eventList = shallowRef()
    const swichTab = (index: number) => {
      actIndex.value = index
      setLineStyle()
    }

    const getEventList = async () => {
      const res = await readEventsList()
      eventList.value = res.map(item => {
        return {
          time: (item.timeType === 1) ? formatDateTime('HH:mm', item.startTime) : formatDateTime('HH:mm', item.startTime) + '-' + formatDateTime('HH:mm', item.endTime),
          ...item,
        }
      })
    }
    getEventList()

    const itemClick = (item: EventItem) => {
      eventEnter(item)
    }

    const setLineStyle = () => {
      const lWidth = document.getElementById('tab-l')?.clientWidth || 0
      const rWidth = document.getElementById('tab-r')?.clientWidth || 0
      if (sLine.value) {
        if (actIndex.value === 0) {
          sLine.value.style.left = '0'
          sLine.value.style.width = lWidth + 'px'
        } else {
          sLine.value.style.left = (lWidth + 16) + 'px'
          sLine.value.style.width = rWidth + 'px'
        }
      }
    }
    onMounted(() => {
      setLineStyle()
    })
    return {
      actIndex,
      eventList,
      sLine,
      swichTab,
      itemClick,
    }
  },
})
</script>

<style scoped lang="scss">
.tab-wrap{
  min-height: 40px;
  max-height: 40px;
  position: relative;
  .tab-item{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: var(--color-tip);
    &.active{
      color: var(--color-text);
      font-weight: bold;
    }
  }
  .s-line{
    width: 48px;
    height: 2px;
    background-color: var(--color-primary);
    position: absolute;
    bottom: 0;
    transition: 0.3s;
  }
}
.text-nowrap{
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
