/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 14:11
* @description
*   TheEvents.vue of WeTrade
*/
<template>
<div v-if="!status" class="loading-wrap df-center">
  <Loading />
</div>
<div class="fill py-16 df-center df-middle" id="economicCalendarWidget">
  <iframe
  scrolling="no"
  allowtransparency="true"
  width="100%"
  height="100%"
  src="https://www.tradays.com/en/economic-calendar/widget?mode=2&utm_source=localhost"
  :onload="iframeLoaded"
  frameborder="0"></iframe>
</div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import Loading from 'common/loading/Donut.vue'

export default defineComponent({
  name: 'TheEconomicCalendar',
  components: { Loading },
  setup () {
    const status = shallowRef(false)
    const iframeLoaded = () => {
      status.value = true
    }
    return {
      status,
      iframeLoaded,
    }
  },
})
</script>

<style scoped lang="scss">
.loading-wrap{
  position: fixed;
  top: 300px;
  left: 50%;
  transform: translate(-50%);
}
</style>
