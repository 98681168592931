<template>
<div class="df-middle item-wrap px-16">
  <div class="date c-text f-bold f-nm mr-16" style="white-space:nowrap;">{{ item.time }}</div>
  <Pic v-if="item.icon" :src="item.icon" class="mr-8" width="24" height="24" />
  <p class="f-nm c-text text-nowrap">{{ item.title }}</p>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheEventItem',
  components: { Pic },
  props: {
    item: Object,
  },
})
</script>

<style scoped lang="scss">
.item-wrap{
  height: 56px;
  border-radius: 6px;
  border: 1px solid var(--color-border);
}
.text-nowrap{
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
