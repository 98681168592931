
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheEventItem',
  components: { Pic },
  props: {
    item: Object,
  },
})
