
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, onMounted, ref, shallowRef } from 'vue'
import TheEventItem from '@/modules/home/components/TheEventItem.vue'
import TheEconomicCalendar from '@/modules/home/components/TheEconomicCalendar.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import { readEventsList, EventItem } from '@/modules/home/home.api'
import { formatDateTime } from '@/common/format'
import { eventEnter } from '@/modules/home/home'

export default defineComponent({
  name: 'Register',
  components: { PageWithHeader, TheEventItem, TheEconomicCalendar, AsyncNullableList, Holder },

  setup () {
    const actIndex = shallowRef(0)
    const sLine = ref<HTMLElement>()
    const eventList = shallowRef()
    const swichTab = (index: number) => {
      actIndex.value = index
      setLineStyle()
    }

    const getEventList = async () => {
      const res = await readEventsList()
      eventList.value = res.map(item => {
        return {
          time: (item.timeType === 1) ? formatDateTime('HH:mm', item.startTime) : formatDateTime('HH:mm', item.startTime) + '-' + formatDateTime('HH:mm', item.endTime),
          ...item,
        }
      })
    }
    getEventList()

    const itemClick = (item: EventItem) => {
      eventEnter(item)
    }

    const setLineStyle = () => {
      const lWidth = document.getElementById('tab-l')?.clientWidth || 0
      const rWidth = document.getElementById('tab-r')?.clientWidth || 0
      if (sLine.value) {
        if (actIndex.value === 0) {
          sLine.value.style.left = '0'
          sLine.value.style.width = lWidth + 'px'
        } else {
          sLine.value.style.left = (lWidth + 16) + 'px'
          sLine.value.style.width = rWidth + 'px'
        }
      }
    }
    onMounted(() => {
      setLineStyle()
    })
    return {
      actIndex,
      eventList,
      sLine,
      swichTab,
      itemClick,
    }
  },
})
