
import { defineComponent, shallowRef } from 'vue'
import Loading from 'common/loading/Donut.vue'

export default defineComponent({
  name: 'TheEconomicCalendar',
  components: { Loading },
  setup () {
    const status = shallowRef(false)
    const iframeLoaded = () => {
      status.value = true
    }
    return {
      status,
      iframeLoaded,
    }
  },
})
